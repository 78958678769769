<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Add Earthquake</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid2" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="lat"
                  :rules="latRules"
                  label="Latitude"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="lon"
                  :rules="lonRules"
                  label="Longitude"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="mag"
                  :rules="magRules"
                  label="Magnitude"
                  required
                />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="mmi" label="MMI" />
              </v-col>
            </v-row>

            <v-btn
              :disabled="!valid2"
              color="success"
              class="mr-4"
              @click="validate"
            >
              Save
            </v-btn>
          </v-form>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Add Earthquake",
  },
  data() {
    return {
      // Validation with submit & clear
      valid2: true,
      lat: "",
      lon: "",
      mag: "",
      mmi: "",
      latRules: [(v) => !!v || "Latitude is required"],
      lonRules: [(v) => !!v || "Longitude is required"],
      magRules: [(v) => !!v || "Magnitude is required"],
    };
  },
  computed: {
    ...mapGetters(["GEMAS_BACKEND"]),
  },
  // Validation with submit & clear
  methods: {
    validate() {
      // this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        let postData = new FormData();
        postData.append("LAT", this.lat);
        postData.append("LON", this.lon);
        postData.append("MAG", this.mag);
        postData.append("MMI", this.mii);
        axios
          .post(
            this.GEMAS_BACKEND + "kejadian_gempa/apiInsertKejadianGempa",
            postData
          )
          .then((res) => {
            this.lat = "";
            this.lon = "";
            this.mag = "";
            this.mmi = "";
          })
          .catch((err) => {
            // handle error
            console.log(err);
          });
      }
    },
  },
};
</script>
